<script lang="ts">
  import { CheckCircle, Zap } from "lucide-svelte";

  import imageSrc from "$lib/assets/marketing/image-render-animation-lantern.jpg";
  import { time } from "$lib/time";

  function easeInSine(x: number) {
    // https://gizma.com/easing/
    x = Math.max(0, Math.min(1, x));
    return 1 - Math.cos((x * Math.PI) / 2);
  }

  function finishBump(x: number) {
    const center = 0.99;
    const radius = 0.03;
    const mag = Math.max(0, radius - Math.abs(x - center)) / radius;
    return easeInSine(mag * mag);
  }

  const renderingDurationMs = 10000;

  const startTime = $time;
  $: progress = easeInSine(($time - startTime) / renderingDurationMs);
  $: bump = finishBump(($time - startTime) / renderingDurationMs);
  $: done = progress >= 0.99;

  const cols = 16;
  const rows = 9;

  /** Generate an ordering of the grid starting in the center and spiraling outward. */
  function makeSpiralOrder(): number[][] {
    // Assumes that `rows` is odd and `cols` is even, or this won't work.
    const order: number[][] = [];
    let i = Math.floor(rows / 2);
    let j = Math.floor((cols - 1) / 2);
    const di = [0, -1, 0, 1];
    const dj = [1, 0, -1, 0];
    let dir = 0;
    let k = 0;
    let steps = 1;
    while (Math.max(i, j) < Math.max(rows, cols) + 1) {
      if (i >= 0 && i < rows && j >= 0 && j < cols) {
        order.push([i, j]);
      }
      i += di[dir];
      j += dj[dir];
      if (++k === steps) {
        k = 0;
        dir = (dir + 1) % 4;
        if (dir % 2 === 0) {
          steps++;
        }
      }
    }
    if (order.length !== rows * cols) {
      throw new Error("Invalid spiral order, double-check code");
    }
    return order;
  }

  const spiralOrder = makeSpiralOrder();

  let opacity: Record<string, number>;
  $: {
    opacity = {};
    let displayedCount = progress * spiralOrder.length;
    for (let i = 0; i < spiralOrder.length; i++) {
      const [row, col] = spiralOrder[i];
      opacity[`${row}-${col}`] =
        0.5 - Math.min(1, Math.max(-1, displayedCount - i)) / 2;
    }
  }
</script>

<div>
  <div
    class="relative aspect-[16/9] rounded-md ring-1 overflow-hidden transition-colors duration-500"
    class:ring-zinc-600={!done}
    class:ring-primary={done}
    style:transform="scale({1 + 0.04 * bump})"
  >
    <img
      src={imageSrc}
      alt="A lantern being gradually rendered, with a progress bar"
      class="object-cover w-full h-full"
    />
    <div class="absolute inset-0 flex flex-col">
      {#each [...Array(rows)] as _, i (i)}
        <div class="flex-1 flex">
          {#each [...Array(cols)] as _, j (j)}
            <div
              class="flex-1 bg-zinc-800"
              style:opacity="{100 * opacity[`${i}-${j}`]}%"
            >
              <svg
                viewBox="0 0 32 32"
                class="w-full h-full bg-[#060908] stroke-white/20"
              >
                <line x1="0" y1="0" x2="32" y2="32" stroke-dasharray="4 2" />
                <line x1="32" y1="0" x2="0" y2="32" stroke-dasharray="4 2" />
              </svg>
            </div>
          {/each}
        </div>
      {/each}
    </div>
  </div>

  <!-- Progress bar -->
  <div class="flex gap-4 items-center pt-4">
    <span class="text-sm font-medium">
      {#if !done}
        <Zap class="inline-block w-4 h-4 mr-0.5 text-zinc-400" />
      {:else}
        <CheckCircle class="inline-block w-4 h-4 mr-0.5 text-primary" />
      {/if}
      {!done ? "Rendering…" : "Rendered!"}</span
    >
    <div class="flex-1 flex h-2.5 bg-white/20 rounded-full overflow-hidden">
      <div
        class="inline-block h-full transition-colors duration-500"
        class:bg-zinc-400={!done}
        class:bg-primary={done}
        style:width="{100 * progress}%"
      />
    </div>
  </div>
</div>

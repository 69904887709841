<script lang="ts">
  import { SlackIcon } from "lucide-svelte";
  import Typewriter from "svelte-typewriter";

  import { analytics } from "$lib/analytics";
  import Cta from "$lib/ui/system/Cta.svelte";
  import Divider from "$lib/ui/system/Divider.svelte";
  import AnimatedCode from "./AnimatedCode.svelte";
  import AnimatedTerminal from "./AnimatedTerminal.svelte";
  import ComputeCosts from "./ComputeCosts.svelte";
  import CustomerLogos from "./CustomerLogos.svelte";
  import FeatureBoxes from "./FeatureBoxes.svelte";
  import LandingUseCases from "./LandingUseCases.svelte";
  import MockExecutionTable from "./MockExecutionTable.svelte";
  import MockMetrics from "./MockMetrics.svelte";
  import MockWindow from "./MockWindow.svelte";
  import SignupCta from "./SignupCta.svelte";
  import StartupDiscount from "./StartupDiscount.svelte";
  import Testimonials from "./Testimonials.svelte";
  import Tweets from "./Tweets.svelte";

  let animationFinished = false;
</script>

<main class="relative bg-grid px-4">
  <div class="max-w-screen-xl mx-auto">
    <div
      class="pt-16 md:pt-32 flex flex-col lg:flex-row gap-x-8 lg:gap-x-12 gap-y-6"
    >
      <div class="w-full lg:flex-1 flex flex-col justify-center items-start">
        <h1 class="type-heading-lg mb-6">
          The serverless
          <span class="xs-inline hidden lg:inline xl:hidden"><br /></span>
          platform
          <span class="hidden sm:inline lg:hidden"><br /></span>
          for
          <span class="xs-inline hidden lg:inline xl:hidden"><br /></span>
          <span class="typewriter">
            <Typewriter
              mode="loop"
              element="span"
              interval={200}
              unwriteInterval={200}
              wordInterval={3000}
            >
              <span class="text-primary">AI</span>
              <span class="text-primary">data</span>
              <span class="text-primary">ML</span>
            </Typewriter></span
          > teams
        </h1>
        <p class="mb-3 type-subtitle-lg leading-[1.5] text-white/70">
          Run generative AI models, large-scale batch jobs, job queues, and much
          more.
        </p>
        <p class="mb-6 type-subtitle-lg leading-[1.5] text-white/70">
          Bring your own code — we run the infrastructure.
        </p>
        <div class="flex flex-row gap-x-4">
          <Cta
            primary
            large
            href="/signup"
            on:click={() => analytics.track("Main Get Started Button Clicked")}
          >
            Get started
          </Cta>
          <a
            href="https://calendly.com/alec-modal/modal-chat"
            target="_blank"
            rel="noreferrer"
            class="btn btn-outlined btn-lg text-white/80 border-white/80 bg-white/5"
            on:click={() => analytics.track("Main Book a Demo Button Clicked")}
            >Book a demo</a
          >
        </div>
      </div>
      <div
        class="flex flex-col flex-wrap sm:flex-row sm:gap-x-4 lg:w-[400px] lg:flex-initial lg:flex-col min-w-0"
      >
        <div
          class="h-[209px] sm:h-[216px] lg:h-[209px] w-full sm:min-w-[400px] mb-6 sm:flex-1 lg:w-full lg:flex-none"
        >
          <MockWindow>
            <AnimatedCode
              show={true}
              showAll={false}
              lines={7}
              source={`import modal

app = modal.App()

@app.function()
def hello():
    print("Running remotely on Modal!")
`}
              bind:finished={animationFinished}
            />
          </MockWindow>
        </div>
        <div
          class="h-[216px] w-full sm:min-w-[270px] sm:flex-1 lg:w-full lg:flex-none"
        >
          <MockWindow>
            <AnimatedTerminal
              sample="hello.py"
              show={animationFinished}
              fontSize="14px"
            />
          </MockWindow>
        </div>
      </div>
    </div>

    <div class="mt-20 mb-20 md:mb-40 lg:mt-40">
      <CustomerLogos />
    </div>

    <div class="my-20 md:my-40">
      <LandingUseCases />
    </div>

    <div
      class="grid md:grid-cols-[3fr,4fr] gap-x-10 lg:gap-x-16 gap-y-8 md:my-20"
    >
      <div class="flex flex-col justify-center items-start gap-6">
        <h2 class="type-title-lg">Engineered for large-scale workloads</h2>
        <p class="text-white/60 type-subtitle-lg">
          We built a container system from scratch in Rust for the fastest
          cold-start times. Scale to hundreds of GPUs and back down to zero in
          seconds, and pay only for what you use.
        </p>
      </div>

      <div class="flex items-center min-w-0 w-full">
        <MockMetrics />
      </div>
    </div>

    <div
      class="flex flex-col-reverse md:grid md:grid-cols-[4fr,3fr] gap-x-10 lg:gap-x-16 gap-y-8 mt-20 md:mt-40"
    >
      <div class="flex flex-col justify-center">
        <MockExecutionTable />
      </div>

      <div class="flex flex-col justify-center items-start gap-6">
        <h2 class="type-title-lg">Iterate at the speed of thought</h2>
        <p class="text-white/60 type-subtitle-lg">
          Deploy functions to the cloud in seconds, with custom container images
          and hardware requirements. Never write a single line of YAML.
        </p>
      </div>
    </div>

    <div class="mt-20 md:mt-40">
      <Divider />
    </div>

    <div class="mt-20 md:mt-04">
      <h2 class="type-title-lg md:text-center mb-8 md:mb-12">
        Everything your app needs
      </h2>
      <div
        class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 auto-rows-fr md:gap-x-8 md:gap-y-8"
      >
        <FeatureBoxes />
      </div>
    </div>

    <div class="mt-20">
      <Divider />
    </div>

    <div class="block my-20">
      <Testimonials keys={["Substack", "RampShort"]} />
    </div>

    <div class="mt-20">
      <Divider />
    </div>

    <div
      class="grid md:grid-cols-[3fr,4fr] gap-x-10 lg:gap-x-16 gap-y-14 mt-20"
    >
      <div class="flex flex-col justify-center items-start gap-6">
        <h2 class="type-title-lg">Only pay for what you use</h2>
        <p class="text-white/60 type-subtitle-lg">
          Scale up to hundreds of nodes and down to zero within seconds. Pay for
          actual compute, by the CPU cycle. With $30 of compute on us, every
          month.
        </p>
        <Cta primary large href="/pricing">See pricing</Cta>
      </div>

      <div>
        <ComputeCosts />
      </div>
    </div>

    <div class="my-20">
      <StartupDiscount with3d />
    </div>

    <div class="my-20">
      <Divider />
    </div>

    <div>
      <div class="text-center">
        <h2 class="type-title-lg">Join Modal's developer community</h2>
      </div>
      <div class="flex w-full justify-center mt-8">
        <Cta
          primary
          large
          href="/slack"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SlackIcon size={16} class="primary mr-2" /> Modal Community Slack
        </Cta>
      </div>
      <div class="sm:px-6 pt-12 sm:mb-16">
        <Tweets />
      </div>
    </div>

    <SignupCta />
  </div>
</main>

<style lang="postcss">
  :where(svg > *) {
    transition: all 0.1s ease-in-out 0.1s;
  }

  @media (max-width: 575px) {
    .xs-inline {
      display: inline;
    }
  }

  .typewriter {
    --cursor-width: 4px;
    --cursor-color: #7fee64;
  }

  .typewriter span span::after {
    height: 1.2em;
  }
</style>

<!-- @component A little display that wraps contents in a rounded browser-like rectangle. -->
<script lang="ts">
  export let hideButtons = false;
</script>

<div class="h-full flex flex-col border border-white/20 rounded-xl">
  {#if !hideButtons}
    <div
      class="flex py-3 px-4 space-x-3 bg-white/10 border-b border-white/[0.05] rounded-t-xl"
    >
      <div class="h-2.5 w-2.5 rounded-full bg-red-500 border border-white/20" />
      <div
        class="h-2.5 w-2.5 rounded-full bg-yellow-500 border border-white/20"
      />
      <div
        class="h-2.5 w-2.5 rounded-full bg-green-500 border border-white/20"
      />
    </div>
  {/if}
  <div
    class="flex-1 px-3.5 min-h-0 py-4 overflow-auto bg-black/10 rounded-b-xl min-w-0"
  >
    <slot />
  </div>
</div>

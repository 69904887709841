<!-- @component An animated, typed-out code sample. -->
<script lang="ts" context="module">
  import { createHighlighter } from "shiki";

  const highlighter = await createHighlighter({
    themes: ["dark-plus"],
    langs: ["python"],
  });
</script>

<script lang="ts">
  import { afterUpdate, onMount } from "svelte";

  export let source: string;
  export let lines: number = 0;
  export let show: boolean;
  export let showAll: boolean;
  export let finished: boolean = false; // output variable; read-only

  let content = "";
  let scrollEl: HTMLDivElement;

  onMount(() => {
    const update = () => {
      if (showAll) {
        if (content !== source) content = source;
      } else if (!show || !source.startsWith(content)) {
        if (content) content = "";
      } else if (content.length < source.length) {
        // The codePointAt() method avoids broken UTF-16 issues with emojis.
        for (let i = 0; i < 3; i++) {
          content += String.fromCodePoint(source.codePointAt(content.length)!);
        }
      }
    };
    const id = setInterval(update, 50);
    return () => clearInterval(id);
  });

  $: finished = content === source;

  afterUpdate(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  });
</script>

<div class="code" bind:this={scrollEl}>
  {#if lines}
    <div class="w-[1.5ch] mr-3 text-right text-zinc-500 select-none">
      {#each [...Array(lines)] as _, i}
        <p>{i + 1}</p>
      {/each}
    </div>
  {/if}
  {@html highlighter.codeToHtml(content.trimEnd(), {
    lang: "python",
    theme: "dark-plus",
  })}
</div>

<style lang="postcss">
  .code {
    @apply h-full flex text-sm overflow-auto font-mono min-w-0;
  }

  .code :global(.shiki) {
    @apply p-0 !bg-transparent;
  }
</style>

<script lang="ts">
  import { time } from "$lib/time";
  import StatusPill from "$lib/ui/dashboard/deployments/StatusPill.svelte";

  export let enqueued: number;
  export let startup: number;
  export let execution: number;

  let startupString: string;
  let executionString: string;
  let statusString = "Pending";
  $: {
    const startupElapsed =
      Math.max(0, Math.min(startup, $time - enqueued)) / 1000;
    const executionElapsed =
      Math.max(0, Math.min(execution, $time - enqueued - startup)) / 1000;
    startupString = startupElapsed.toFixed(1) + "s";
    executionString =
      executionElapsed > 0 ? executionElapsed.toFixed(1) + "s" : "-";
  }
  $: if ($time > enqueued + startup) statusString = "Running";
  $: if ($time > enqueued + startup + execution) statusString = "Succeeded";
</script>

<div>{new Date(enqueued).toLocaleTimeString()}</div>
<div>{startupString}</div>
<div>{executionString}</div>
<div class="flex max-h-6">
  <StatusPill type={statusString.toLowerCase()} text={statusString} />
</div>

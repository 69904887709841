<script lang="ts" context="module">
  import { codeToHtml } from "shiki";

  const content = `base_model: mistralai/Mistral-7B-v0.1
model_type: MistralForCausalLM
tokenizer_type: LlamaTokenizer
is_mistral_derived_model: true

adapter: lora
lora_r: 16
lora_alpha: 32
lora_dropout: 0.05
lora_target_linear: true
gradient_accumulation_steps: 1
micro_batch_size: 32
num_epochs: 4
optimizer: adamw_torch
lr_scheduler: cosine
learning_rate: 0.0001
deepspeed: /root/axolotl/deepspeed_configs/zero3_bf16.json
wandb_project: mistral_7b_sqlqa_finetune`;

  const contentHighlighted = await codeToHtml(content, {
    lang: "yaml",
    theme: "dark-plus",
  });
</script>

<script lang="ts">
  import clsx from "clsx";
  import { Cpu, Image, Zap } from "lucide-svelte";
  import { onMount } from "svelte";

  import FineTuningIcon from "$lib/assets/icons/FineTuningIcon.svelte";
  import ModelInferenceIcon from "$lib/assets/icons/ModelInferenceIcon.svelte";
  import Cta from "$lib/ui/system/Cta.svelte";
  import ImageRenderAnimation from "./ImageRenderAnimation.svelte";

  let tabIndex = 0;
  let buttons: HTMLButtonElement[] = [];
  let backgroundStyle = "";
  let interval: ReturnType<typeof setInterval>;

  $: newTabIndex(buttons[tabIndex]);

  function newTabIndex(button: HTMLButtonElement) {
    backgroundStyle = button
      ? `left: ${button.offsetLeft - 2}px; width: ${button.offsetWidth}px;`
      : "";
  }

  function handleResize() {
    newTabIndex(buttons[tabIndex]);
  }

  function startInterval() {
    clearInterval(interval);
    interval = setInterval(() => {
      tabIndex = (tabIndex + 1) % 3;
    }, 30000);
  }

  function setTabIndex(idx: number) {
    tabIndex = idx;
    startInterval();
  }

  onMount(() => {
    startInterval();
    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  });

  const data = [
    {
      label: "AI Inference",
      heading: "The only runtime environment you need for Generative AI",
      ctaLink: "/docs/examples/stable_diffusion_xl_turbo",
      cards: [
        {
          subtitle: "Fast cold boots",
          text: "Load gigabytes of weights in seconds with our optimized container file system.",
        },
        {
          subtitle: "Bring your own code",
          text: "Deploy your model on Modal's custom runtime.",
        },
        {
          subtitle: "Seamless autoscaling",
          text: "When your app gets an influx of traffic, Modal scales with you.",
        },
      ],
    },
    {
      label: "Fine-tuning",
      heading: "Fine-tune for any domain, on any data",
      ctaLink: "/docs/examples/llm-finetuning",
      cards: [
        {
          subtitle: "Top-of-the-line hardware",
          text: "Set up Nvidia A100 and H100 GPUs in seconds.",
        },
        {
          subtitle: "Start training immediately",
          text: "Modal provisions compute instances and installs hardware drivers for you.",
        },
        {
          subtitle: "Cloud storage",
          text: "Mount weights and data in distributed volumes, then access them wherever they’re needed.",
        },
      ],
    },
    {
      label: "Batch Processing",
      heading: "Perfect for bread-and-butter compute workloads",
      ctaLink: "/docs/examples/s3_bucket_mount",
      cards: [
        {
          subtitle: "Supercomputing scale",
          text: "100x more resources than AWS Lambda: CPU, GPU, RAM, and disk.",
        },
        {
          subtitle: "Serverless pricing",
          text: "Pay only for resources consumed, by the second, as you spin up containers.",
        },
        {
          subtitle: "Powerful data structures",
          text: "Define distributed queues and key-value stores with a single line of Python.",
        },
      ],
    },
  ];
</script>

<div
  class="flex flex-col gap-y-3 justify-end h-28 mb-4 lg:flex-row lg:gap-x-8 lg:justify-between lg:items-center xl:h-20"
>
  <h2 class="type-heading-sm">{data[tabIndex].heading}</h2>
  <div
    class="relative flex flex-row rounded-lg border-2 border-white/20 bg-white/10 sm:w-min"
  >
    {#each data as tab, index}
      <button
        bind:this={buttons[index]}
        class="flex-auto h-8 flex flex-row gap-x-3 justify-center items-center sm:px-5 sm:h-9 xl:h-11"
        on:click={() => setTabIndex(index)}
      >
        <span
          class={clsx(
            "hidden sm:inline transition-all duration-200",
            tabIndex == index ? "text-primary" : "opacity-60 text-white",
          )}
        >
          {#if index == 0}
            <ModelInferenceIcon class="w-6 h-6" />
          {:else if index == 1}
            <FineTuningIcon class="w-6 h-6" />
          {:else}
            <Cpu class="w-6 h-6 -mr-0.5" />
          {/if}
        </span>
        <span
          class={clsx(
            "text-sm font-medium whitespace-nowrap transition-colors duration-200 sm:text-base xl:text-lg",
            tabIndex == index ? "text-primary" : "text-white/80",
          )}
        >
          {tab.label}
        </span>
      </button>
    {/each}
    <div
      class="absolute -top-[2px] -bottom-[2px] bg-primary/10 border-primary border-2 rounded-lg box-content transition-all duration-200 ease-out"
      style={backgroundStyle}
    />
  </div>
</div>

<div
  class="py-6 flex flex-col justify-between gap-y-8 md:flex-row-reverse md:gap-x-10 relative z-0"
>
  <div class="w-full md:w-1/2 shrink-0">
    {#if tabIndex === 0}
      <div class="flex flex-row items-center justify-center gap-4">
        <div class="flex-basis-0 flex-grow flex flex-col gap-3 items-start">
          <div class="relative">
            <img
              loading="lazy"
              src="https://modal-public-assets.s3.us-east-1.amazonaws.com/turbo-art-reference-dog.gif"
              alt="Reference dog"
              class="rounded"
            />
            <div
              class="absolute top-0 bottom-0 left-0 right-0 border-2 border-white/20 rounded pointer-events-none"
            ></div>
          </div>
          <div class="text-sm font-medium">
            <Image class="inline-block w-4 h-4 mr-0.5 text-zinc-400 mb-0.5" /> Original
          </div>
        </div>
        <div class="flex-basis-0 flex-grow flex flex-col gap-3 items-start">
          <div class="relative">
            <img
              loading="lazy"
              src="https://modal-public-assets.s3.us-east-1.amazonaws.com/turbo-art-generated-dog.gif"
              alt="Generated dog"
              class="rounded"
            />
            <div
              class="absolute top-0 bottom-0 left-0 right-0 border-2 border-white/20 rounded pointer-events-none"
            ></div>
          </div>
          <div class="text-sm font-medium">
            <Zap class="inline-block w-4 h-4 mr-0.5 text-zinc-400 mb-0.5" /> Turbo
            XL
          </div>
        </div>
      </div>
    {:else if tabIndex === 1}
      <div class="flex flex-col w-full mb-6">
        <div
          class="bg-white/20 text-sm font-medium font-mono rounded-t-xl py-3 px-4 w-full border border-white/10 border-b-white/5"
        >
          config/mistral.yml
        </div>
        <div
          class="w-full text-sm border-x border-b border-white/20 rounded-b-xl bg-white/10 code max-h-40 md:max-h-52 overflow-y-auto code"
        >
          {@html contentHighlighted}
        </div>
      </div>

      <div class="flex flex-col w-full">
        <div
          class="w-full text-sm rounded-t-xl bg-primary/20 text-primary font-medium font-mono py-1.5 px-4 border border-primary/10 border-b-primary/5"
        >
          &gt;_
        </div>
        <div
          class="p-4 md:pr-2 flex flex-row text-sm gap-2.5 bg-primary/10 font-mono w-full overflow-x-auto rounded-b-xl border-x border-b border-primary/20"
        >
          <div class="text-primary/60">$</div>
          <div class="text-primary whitespace-nowrap">
            modal run src.train --config=mistral.yml --data=sqlqa.jsonl
          </div>
        </div>
      </div>
    {:else}
      <ImageRenderAnimation />
    {/if}
  </div>
  <div class="w-full md:w-1/2">
    <div class="flex flex-col gap-4 mb-6">
      {#each data[tabIndex].cards as { subtitle, text }}
        <div class="py-4 px-5 rounded-lg text-white/80 bg-white/10">
          <p class="type-title-sm font-medium mb-1">{subtitle}</p>
          <p class="type-subtitle-sm">{text}</p>
        </div>
      {/each}
    </div>
    <Cta primary large href={data[tabIndex].ctaLink}>Learn more</Cta>
  </div>
</div>

<style lang="postcss">
  .code :global(.shiki) {
    @apply p-4 !bg-transparent;
  }
</style>

<script lang="ts">
  import { afterUpdate, onMount } from "svelte";

  export let sample: "hello.py" | "image.py" | "hello_world.py";
  export let show: boolean;
  export let fontSize: string = "14px";

  const durations: Record<typeof sample, number> = {
    "hello.py": 60,
    "image.py": 101,
    "hello_world.py": 80,
  };

  let preEl: HTMLPreElement;
  let step: number = 0;

  onMount(() => {
    const update = () => {
      if (show) step++;
      else step = 0;
    };
    const id = setInterval(update, 30);
    return () => clearInterval(id);
  });

  $: spin = "⠋⠙⠹⠸⠼⠴⠦⠧⠇⠏".charAt(Math.floor(step / 3) % 10);

  afterUpdate(() => {
    if (step < durations[sample] && preEl) {
      preEl.scrollTop = preEl.scrollHeight;
    }
  });
</script>

<!--
  This code doesn't look great because of all the white-space formatting.

  If we make more of these animations in the future, it may be worth writing a
  quick library for defining them more ergonomically.
-->
<pre style:font-size={fontSize} bind:this={preEl}>
{#if sample === "hello.py"}
    <code class="text-primary">> </code><code class="text-primary"
      >{"modal run hello.py".slice(0, Math.max(0, step - 10))}</code
    >
{#if step >= 30}<code class="text-green-600">✓</code
      > Initialized.{/if}
{#if step >= 31 && step < 39}
      <code class="text-indigo-400">{spin} Creating objects...</code>
    {/if}{#if step >= 39}<code class="text-green-600">✓</code
      > Created objects.
<code class="text-white/20">├──</code
      > 🔨 Created hello.
<code class="text-white/20">└──</code
      > 🔨 Mounted ./hello.py.
    {/if}
{#if step >= 57}<code class="text-indigo-400"
        >Running remotely on Modal!
</code>{/if}{#if step >= 42 && step < 59}<code class="text-indigo-400"
        >{spin} Running app...</code
      >
    {/if}{#if step >= 59}<code class="text-green-600">✓</code> App completed.
    {/if}
  {:else if sample === "image.py"}
    <code class="text-lime-300">> </code><code class="text-lime-200"
      >{"python image.py".slice(0, Math.max(0, step - 10))}</code
    >
{#if step >= 30}<code class="text-green-600">✓</code
      > Initialized.{/if}
{#if step >= 33}<code class="text-yellow-300"
        >Building image im-pjhcebniszpdwgzrbx
</code>{/if}{#if step >= 35}<code class="text-yellow-300">
=> Step 0: FROM base
</code>{/if}{#if step >= 38}<code class="text-yellow-300">
=> Step 1: RUN pip install scikit-learn pandas
</code>{/if}{#if step >= 58}<code class="text-yellow-300">
Collecting scikit-learn
  ... abbreviated
</code>{/if}{#if step >= 76}<code class="text-yellow-300">
Collecting pandas
  ... abbreviated
</code>{/if}{#if step >= 82}<code class="text-yellow-300">
Saving image...
</code>{/if}{#if step >= 90}<code class="text-yellow-300"
        >Image saved, took 0.26s
</code>{/if}{#if step >= 33 && step < 100}<code class="text-indigo-400"
        >{spin} Creating objects...</code
      >{/if}{#if step >= 100}<code class="text-green-600">✓</code
      > Created objects.
<code class="text-white/20">├──</code
      > 🔨 Created train_ml_model.
<code class="text-white/20">└──</code> <code
        >🔨 Mounted ./image.py.
</code>{/if}{#if step >= 100}<code class="text-green-600">✓</code
      > App completed.
    {/if}
  {:else if sample == "hello_world.py"}
    <code class="text-primary">> </code><code class="text-primary"
      >{"modal run hello.py".slice(0, Math.max(0, step - 10))}</code
    >
{#if step >= 30}<code class="text-green-600">✓</code
      > Initialized.{/if}
{#if step >= 31 && step < 39}
      <code class="text-indigo-400">{spin} Creating objects...</code>
    {/if}{#if step >= 39}<code class="text-green-600">✓</code
      > Created objects.
<code class="text-white/20">├──</code
      > 🔨 Created hello.
<code class="text-white/20">└──</code
      > 🔨 Mounted ./hello.py.
    {/if}
{#if step >= 57}<code class="text-indigo-400"
        >{`[{"generated_text": "Hello World! I am a language model."}]`}
</code>{/if}{#if step >= 42 && step < 59}<code class="text-indigo-400"
        >{spin} Running app...</code
      >
    {/if}{#if step >= 59}<code class="text-green-600">✓</code> App completed.
    {/if}
  {/if}
</pre>

<style lang="postcss">
  pre {
    @apply h-full whitespace-pre-wrap overflow-x-hidden overflow-y-auto px-2.5;
  }
</style>

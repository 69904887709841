<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import MockExecutionLine from "./MockExecutionLine.svelte";

  function getRandomInput(enqueued: number, cold: boolean) {
    const startup = cold ? Math.floor(Math.random() * 400) + 800 : 0;
    const execution = Math.floor(Math.random() * 1200) + 1200;
    return { enqueued: enqueued, startup: startup, execution: execution };
  }

  const N = 4;

  let inputs = [
    getRandomInput(Date.now() - 6000, true),
    getRandomInput(Date.now() - 5000, false),
    getRandomInput(Date.now() - 4000, false),
    getRandomInput(Date.now(), true),
  ];

  let last_n = 1;
  const push = () => {
    const n = Math.floor(Math.random() * N) + 1;
    for (let i = 0; i < n; i++) {
      inputs.push(getRandomInput(Date.now() + i, i >= last_n));
    }
    inputs = inputs.slice(-N);
    last_n = n;
  };
  let id: NodeJS.Timer;
  onMount(() => {
    id = setInterval(push, 4000);
    return () => {
      clearInterval(id);
    };
  });
</script>

<div
  class="flex flex-col border border-white/20 rounded-xl bg-black/10 tabular-nums overflow-auto"
>
  <div class="flex text-sm md:text-base font-normal">
    <div class="bg-white/10 rounded-t-xl table-grid py-1.5 px-4">
      <div>Enqueued</div>
      <div>Startup</div>
      <div>Execution</div>
      <div>Status</div>
    </div>
  </div>
  <div class="flex flex-wrap text-sm md:text-base font-normal overflow-visible">
    {#each [...inputs].reverse() as { enqueued, startup, execution } (enqueued)}
      <div
        in:fade
        class="table-grid px-4 py-1.5 hover:bg-white/5 first:pt-2.5 last:pb-2.5 last:rounded-b-xl"
      >
        <MockExecutionLine {enqueued} {startup} {execution} />
      </div>
    {/each}
  </div>
</div>

<style lang="postcss">
  .table-grid {
    @apply grid grid-cols-[minmax(96px,_4fr),minmax(64px,_3fr),minmax(78px,_3fr),minmax(96px,_5fr)] lg:grid-cols-4 grow;
  }
</style>

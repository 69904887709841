<script lang="ts">
  /* Updating tweets? Make sure to read assets/tweets/README.md to use the script. */
  import clsx from "clsx";
  import { ArrowUpRightIcon } from "lucide-svelte";

  import * as _tweets from "$lib/assets/tweets/tweets.json";

  interface Tweet {
    img: string;
    text: string;
    name: string;
    username: string;
    url: string;
    job_title: string;
  }

  const tweets: Tweet[] = (_tweets as any).default;
</script>

<div class="sm:columns-2 md:columns-3 gap-6">
  {#each tweets as tweet (tweet.url)}
    <div
      class="inline-block mb-6 rounded-xl border border-white/5 hover:border-white/10 bg-white/5 text-white/80 p-6 group transition-all"
    >
      <div class="flex flex-col gap-4">
        <div class="flex items-center">
          <a
            target="_blank"
            rel="noreferrer"
            href={tweet.url}
            class="rounded-full w-10 h-10 overflow-hidden"
          >
            <img
              src={tweet.img}
              alt="Twitter profile {tweet.username}"
              role="presentation"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={tweet.url}
            class="ml-4 text-sm flex flex-col"
          >
            <div class="font-bold text-white/80 text-base">{tweet.name}</div>
            <div class="text-white/60 text-base">{tweet.job_title}</div>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={tweet.url}
            class={clsx(
              "ml-auto rounded-full border border-white/10 p-3 hover:bg-white/5 active:bg-white/10",
              "-translate-x-4 group-hover:translate-x-0 -rotate-45 group-hover:rotate-0 opacity-0 group-hover:opacity-100",
              "transition-all cursor-pointer duration-400",
            )}
          >
            <ArrowUpRightIcon size={16} />
          </a>
        </div>
        {@html tweet.text}
      </div>
    </div>
  {/each}
</div>

<style lang="postcss">
  div :global(p > a) {
    @apply underline underline-offset-4;
  }
</style>

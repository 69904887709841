import type { PageLoad } from "./$types";

export const prerender = true;

export const load: PageLoad = () => {
  return {
    title: "Modal: High-performance cloud for developers",
    ogTitle: "Modal: High-performance cloud for developers",
    ogDescription:
      "Bring your own code, and run CPU, GPU, and data-intensive compute at scale. The serverless platform for AI and data teams.",
  };
};

<script lang="ts">
  import {
    Codepen,
    Database,
    Globe,
    LineChart,
    ShieldCheck,
    Timer,
  } from "lucide-svelte";

  import FeatureBox from "./FeatureBox.svelte";
</script>

<FeatureBox showImage={true}>
  <Codepen size={24} slot="icon" />
  <div slot="title">Environments</div>
  <div>
    Express <a class="underline" href="/docs/guide/custom-container"
      >container images</a
    >
    and
    <a class="underline" href="/docs/guide/gpu">hardware specifications</a> entirely
    in code.
  </div>
  <div>Say goodbye to Dockerfiles and YAML.</div>
</FeatureBox>

<FeatureBox showImage={true}>
  <Database size={24} slot="icon" />
  <div slot="title">Storage</div>
  <div>
    Provision <a class="underline" href="/docs/guide/volumes">network volumes</a
    >,
    <a class="underline" href="/docs/guide/dicts-and-queues#modal-dicts"
      >key-value stores</a
    >
    and
    <a class="underline" href="/docs/guide/dicts-and-queues#modal-queues"
      >queues</a
    > with ease.
  </div>
  <div>Use powerful cloud primitives that feel like regular Python.</div>
</FeatureBox>

<FeatureBox showImage={true}>
  <Timer size={24} slot="icon" />
  <div slot="title">Job scheduling</div>
  <div>
    Turn functions into <a class="underline" href="/docs/guide/cron"
      >cron jobs</a
    > with a single line of code.
  </div>
  <div>
    <a class="underline" href="/docs/reference/modal.Function#spawn"
      >Spawn compute intensive jobs</a
    > without blocking your backend.
  </div>
</FeatureBox>

<FeatureBox showImage={true}>
  <Globe size={24} slot="icon" />
  <div slot="title">Web endpoints</div>
  <div>
    Serve any function as an <a class="underline" href="/docs/guide/webhooks"
      >HTTPS endpoint</a
    >.
  </div>
  <div>
    Ship to your own <a
      class="underline"
      href="/docs/guide/webhook-urls#custom-domains">custom domains</a
    >.
  </div>
</FeatureBox>

<FeatureBox showImage={true}>
  <LineChart size={24} slot="icon" />
  <div slot="title">Observability</div>
  <div>Monitor executions, logs and metrics in real time.</div>
  <div>
    Debug interactively with <a
      class="underline"
      href="/docs/guide/developing-debugging#interactive-shell">modal shell</a
    >.
  </div>
</FeatureBox>

<FeatureBox showImage={true}>
  <ShieldCheck size={24} slot="icon" />
  <div slot="title">Security</div>
  <div>Secure your workloads with our battle-tested gVisor runtime.</div>
  <div>
    <a class="underline" href="/docs/guide/security#soc-2"
      >Industry-standard SOC 2 compliance</a
    >.
  </div>
</FeatureBox>
